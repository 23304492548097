import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'
import {
  ADMIN_ROUTE_PATH,
  KALP_ROUTE_PATH,
  LOGIN_ROUTE_PATH,
  START_ROUTE_PATH,
  RESULT_ROUTE_PATH
} from './application/data-types'
import {authGuard} from './application/auth.guard'

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: LOGIN_ROUTE_PATH
  },
  {
    path: KALP_ROUTE_PATH,
    loadChildren: () => import('./2-kalp/kalp.module').then((m) => m.KalpModule),
    canActivate: [authGuard]
  },
  {
    path: LOGIN_ROUTE_PATH,
    loadChildren: () => import('./0-login/login.module').then(m => m.LoginModule)
  },
  {
    path: ADMIN_ROUTE_PATH,
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [authGuard]
  },
  {
    path: START_ROUTE_PATH,
    loadChildren: () => import('./1-start/start.module').then((m) => m.StartModule),
    canActivate: [authGuard]
  },
  {
    path: RESULT_ROUTE_PATH,
    loadChildren: () => import('./3-result/result.module').then((m) => m.ResultModule),
    canActivate: [authGuard]
  },
  {
    path: '**',
    redirectTo: LOGIN_ROUTE_PATH
  }
]


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false, useHash: true,
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
