import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {RouterModule} from '@angular/router'
import {MatButtonModule} from '@angular/material/button'
import {HeaderComponent} from './header/header.component'
import {MatMenuModule} from '@angular/material/menu'
import {MatIconModule} from '@angular/material/icon'
import {BankIdModule} from '@sparbanken-syd/sparbanken-syd-bankid'
import {TestComponent} from './test/test.component'
import {MatCheckboxModule} from '@angular/material/checkbox'
import {ReactiveFormsModule} from '@angular/forms'
import {RandomPnrComponent} from './random-pnr/random-pnr.component'

@NgModule({
  declarations: [
    HeaderComponent,
    TestComponent,
    RandomPnrComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    BankIdModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatCheckboxModule,
    ReactiveFormsModule,
  ],
  exports: [
    HeaderComponent,
    TestComponent,
    RandomPnrComponent
  ]
})

export class SpbCommonModule {
}
