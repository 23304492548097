import {Injectable} from '@angular/core'
import {Applicant, Car, Child, Income, Kalpylator, Loan, Parameters, Property} from '@sparbanken-syd/kalpylator'
import {BehaviorSubject, merge, Observable, Subject} from 'rxjs'
import {PropertyType} from '@sparbanken-syd/kalpylator/types/public'

export type TDomains = 'borgo' | 'sparbanken'
const YEARLY_TAX_VALUE = 9287
export const MONTHLY3_TEMPLATE_INTEREST = 6

@Injectable({
  providedIn: 'root'
})
export class KalpService {

  /**
   * We store the last step here, if the user
   * was in step 6 when asking for a KALP we can
   * remember that for them. Basically just reusing this
   * service for whatever.
   */
  public lastStep = 0

  /**
   * Below subjects are for sending data to us.
   */
  public applicants$: BehaviorSubject<Applicant[]> = new BehaviorSubject<Applicant[]>([])

  public children$: BehaviorSubject<Child[]> = new BehaviorSubject<Child[]>([])

  public incomes$: BehaviorSubject<Income[]> = new BehaviorSubject<Income[]>([])

  public loans$: BehaviorSubject<Loan[]> = new BehaviorSubject<Loan[]>([])

  public properties$: BehaviorSubject<Property[]> = new BehaviorSubject<Property[]>([])

  public cars$: BehaviorSubject<Car[]> = new BehaviorSubject<Car[]>([])

  public changes$: Observable<any>

  public applicantCount$: Observable<number>

  public calculationsPossible$: Observable<boolean>

  /**
   * Help innocent bystanders know if we have calculated
   * something. Loads of rows for no real value?
   */
  private pCalculationsPossible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  private applicantCount: BehaviorSubject<number> = new BehaviorSubject<number>(1)
  private borgoChildrenBenefit = 1250
  private borgo: Parameters = {
    domain: 'borgo',
    defaultTaxTable: 34, //TODO: doesnt work because kalpylator always 33
    livingExpenseDeduction: [
      {class: ['EMPLOYED', 'EMPLOYED'], deduction: 2400}, // Total 16 800
      {class: ['EMPLOYED', 'RETIRED'], deduction: 3300}, // Total 14 600
      {class: ['RETIRED', 'RETIRED'], deduction: 2200} // Total 14 400
    ],
    livingExpenses: [
      {age: 6, expense: 3400 + this.borgoChildrenBenefit}, // No childcare costs are included in the standard deduction
      {age: 13, expense: 4300},
      {age: 18, expense: 5600},
      {age: 64, expense: 9600},
      {age: 67, expense: 8300},
      {age: 200, expense: 8300}
    ],
    cityDwellerFactor: 1.05,
    almostRetiredThreshold: 60, // Borgo, 62 for bank
    almostRetiredLivingExpense: 8301, // Borgo + 1, 9600 for bank, so that we know if this kicks in
    almostRetiredIncomeFactor: 0.7,
    childrenBenefit: this.borgoChildrenBenefit,
    childrenBenefitExtra: [0, 0, 150, 730, 1740, 2990, 4240],
    loanDefaults: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      MORTGAGE: {
        interest: MONTHLY3_TEMPLATE_INTEREST / 100,
        mortgagePercent: 0.01
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      BLANCO: {
        interest: 0.09,
        mortgagePercent: 0.1
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      CREDIT: {
        interest: 0.07,
        mortgagePercent: 0.1
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      BORGEN: {
        interest: 0.05,
        mortgagePercent: 0.1
      }
    },
    propertyDefaults: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      VILLA: {
        runCost: 6200,
        yearlyTax: YEARLY_TAX_VALUE
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      HYRES: {
        runCost: 500,
        yearlyTax: 0
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      BOSTADSRATT: {
        runCost: 800,
        yearlyTax: 0
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      FRITIDSHUS: {
        runCost: 2800,
        yearlyTax: YEARLY_TAX_VALUE
      }
    },
    carDefaults: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      LEASE: {
        cost: 4700 // Borgo cost
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      OWN: {
        cost: 2500 // Borgo
      }
    },
    mortgageInterestRate: {
      month3: 0,
      year1: 0,
      year2: 0,
      year3: 0,
      year4: 0,
      year5: 0
    }
  }
  private sparbanken: Parameters = {
    defaultTaxTable: 33,
    domain: 'default',
    // TODO: Deduction differs from Borgo
    livingExpenseDeduction: [
      {class: ['EMPLOYED', 'EMPLOYED'], deduction: 2400},
      {class: ['EMPLOYED', 'RETIRED'], deduction: 2000},
      {class: ['RETIRED', 'RETIRED'], deduction: 2000}
    ],
    livingExpenses: [
      {age: 6, expense: 3400},
      {age: 13, expense: 4300},
      {age: 17, expense: 5600},
      {age: 64, expense: 9600},
      {age: 67, expense: 7600},
      {age: 200, expense: 7600}
    ],
    cityDwellerFactor: 1,
    almostRetiredThreshold: 62,
    almostRetiredLivingExpense: 9100,
    almostRetiredIncomeFactor: 0.7,
    childrenBenefit: 1250, // As of 20-22
    childrenBenefitExtra: [0, 0, 150, 730, 1740, 2990, 4240],
    loanDefaults: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      MORTGAGE: {
        interest: MONTHLY3_TEMPLATE_INTEREST / 100,
        mortgagePercent: 0.01
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      BLANCO: {
        interest: 0.09,
        mortgagePercent: 0.1
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      CREDIT: {
        interest: 0.09,
        mortgagePercent: 0
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      BORGEN: {
        interest: 0.1,
        mortgagePercent: 0.1
      }
    },
    propertyDefaults: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      VILLA: {
        runCost: 6200,
        yearlyTax: YEARLY_TAX_VALUE
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      HYRES: {
        runCost: 500,
        yearlyTax: 0
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      BOSTADSRATT: {
        runCost: 800,
        yearlyTax: 0
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      FRITIDSHUS: {
        runCost: 2800,
        yearlyTax: YEARLY_TAX_VALUE
      }
    },
    carDefaults: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      LEASE: {
        cost: 4700 // Standard SPB
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      OWN: {
        cost: 2500 // Standard SPB
      }
    },
    mortgageInterestRate: {
      month3: 0,
      year1: 0,
      year2: 0,
      year3: 0,
      year4: 0,
      year5: 0
    }
  }

  private readonly propertyMap: { [key in TDomains]: Parameters }

  private readonly inputs: Subject<any>[]

  constructor() {
    this.calculationsPossible$ = this.pCalculationsPossible$.asObservable()

    this.propertyMap = {
      borgo: this.borgo,
      sparbanken: this.sparbanken
    }

    // All our inputs if someone cares.
    this.inputs = [this.applicants$, this.children$, this.incomes$, this.loans$, this.properties$, this.cars$]
    this.changes$ = merge(...this.inputs)

    // Get and emit number of applicants
    this.applicantCount$ = this.applicantCount.asObservable()
    this.applicants$.subscribe({
      next: (a: Applicant[]) => this.applicantCount.next(a.length)
    })
  }

  public setInterest(interest: any): void {
    this.borgo.mortgageInterestRate.month3 = interest.month3 / 100
    this.sparbanken.mortgageInterestRate.month3 = interest.month3 / 100
  }

  public getKalpylator(domain: TDomains): Kalpylator {
    // Yes we have enough data? This is in the wrong place, but
    // if someone asks for a Kalpylator then we are good to go.
    this.pCalculationsPossible$.next(true)

    const k = new Kalpylator(this.propertyMap[domain])

    // Make copies to avoid trashing the originals...
    k.set<Applicant>(this.applicants$.value.map((a: Applicant) => Object.assign({}, a)))
    k.set<Income>(this.incomes$.value.map((i: Income) => Object.assign({}, i)))
    k.set<Property>(this.properties$.value.map((p: Property) => Object.assign({}, p)))
    k.set<Child>(this.children$.value.map((c: Child) => Object.assign({}, c)))
    k.set<Loan>(this.loans$.value.map((l: Loan) => Object.assign({}, l)))
    k.set<Car>(this.cars$.value.map((c: Car) => Object.assign({}, c)))
    return k
  }

  /**
   * Get the default run cost for a property (as in a building)
   */
  public propertyDefaults(property: Property, domain: TDomains): Property {
    const defaults = this.propertyMap[domain]
    property.runCost = defaults.propertyDefaults[property.propertyType].runCost
    return property
  }

  public reset(): void {
    this.inputs.forEach((s: Subject<any>) => s.next([]))
    this.pCalculationsPossible$.next(false)
    this.lastStep = 0
  }

  /**
   * Added to get some direct access to run cost in help text
   */
  public getRunCost(domain: TDomains, propertyType: PropertyType = 'VILLA'): number {
    return this.propertyMap[domain].propertyDefaults[propertyType].runCost
  }

  /**
   * Added to get the tax in help texts
   */
  public getYearlyTax(domain: TDomains, propertyType: PropertyType = 'VILLA'): number {
    return this.propertyMap[domain].propertyDefaults[propertyType].yearlyTax
  }
}
