import {Component, Input} from '@angular/core'
import {environment} from '../../../environments/environment'
import {AbstractControl, FormControl} from '@angular/forms'
import {generate} from '@sparbanken-syd/personnummer'

@Component({
  selector: 'spb-random-pnr',
  templateUrl: './random-pnr.component.html',
  styleUrls: ['./random-pnr.component.scss']
})
export class RandomPnrComponent {

  @Input() control: AbstractControl = new FormControl()

  /**
   * Test functions available?
   */
  public testMode: boolean = environment.testMode

  public set(): void {
    const res = generate()
    this.control.setValue(res)
  }
}
