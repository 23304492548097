import {inject} from '@angular/core'
import {Router} from '@angular/router'
import {ConfigService, SpbConfiguration} from '../services/config.service'
import {LOGIN_ROUTE_PATH} from './data-types'
import {map, Observable} from 'rxjs'

export const authGuard = (): Observable<boolean | Promise<boolean>> => {

  const router = inject(Router)
  const configService = inject(ConfigService)

  return configService.configState$.pipe(
    map((config: SpbConfiguration) => {
      if (config.bkUser || config.bkAdmin) {
        return true
      }
      return router.navigate([LOGIN_ROUTE_PATH])
    })
  )
}
